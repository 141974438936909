import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'image-view-dialog',
  templateUrl: './image-view-dialog.component.html',
  styleUrls: ['./image-view-dialog.component.scss']
})
export class ImageViewDialogComponent {

  kepek: (string | ArrayBuffer)[] = [];
  index: number = 0;

  feedbackSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: 'fadeOut',
    autoplayHoverPause: true,
    autoplay: false,
    mouseDrag: false,
    items: 1,
    navText: [
      '<i class=\'fa fa-angle-left\'></i>',
      '<i class=\'fa fa-angle-right\'></i>'
    ]
  };

  constructor(
      public dialogRef: MatDialogRef<ImageViewDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.kepek = data.kepek;
    this.index = data.index;
  }

  getHirdetesImage(kepString: string): string {
    return kepString.length ? 'data:image/jpeg;base64,' + kepString : 'feltoltesAlatt';
  }

}

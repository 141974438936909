import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  SuccessSnackbarComponent
} from '../common/snackbar/success-snackbar/success-snackbar.component';
import {ErrorSnackbarComponent} from '../common/snackbar/error-snackbar/error-snackbar.component';

export interface CarDialogData {
  title: string;
  id: string;
}

export interface HirdetesData {
  cim: string;
  rovidLeiras: string;
  hosszuLeiras: string;
  marka: string;
  modell: string;
  ar: string;
  kedvezmenyesAr?: string;
  motor: string;
  kivitel: string;
  uzemanyag: string;
  sebessegvalto: string;
  allapot: string;
  hengerurtartalom: string;
  teljesitmeny: string;
}

@Component({
  selector: 'car-dialog',
  templateUrl: 'car-dialog.component.html',
  styleUrls: ['car-dialog.component.scss'],
})
export class CarDialogComponent {
  form: FormGroup;
  selectedFiles: File[] = [];
  previewImageUrls: (string | ArrayBuffer)[] = [];
  hoverIndex: number | null = null;


  constructor(
      public dialogRef: MatDialogRef<CarDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private formBuilder: FormBuilder,
      private apiService: ApiService,
      private snackbar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      cim: [data.cim ?? '', Validators.required],
      rovidLeiras: [data.rovidLeiras ?? '', [Validators.required, Validators.max(250)]],
      hosszuLeiras: [data.hosszuLeiras ?? '', Validators.required],
      marka: [data.marka ?? '', Validators.required],
      modell: [data.modell ?? '', Validators.required],
      ar: [data.ar ?? '', Validators.required],
      kedvezmenyesAr: [data.kedvezmenyesAr ?? ''],
      motor: [data.motor ?? '', Validators.required],
      kivitel: [data.kivitel ?? '', Validators.required],
      uzemanyag: [data.uzemanyag ?? '', Validators.required],
      sebessegvalto: [data.sebessegvalto ?? '', Validators.required],
      allapot: [data.allapot ?? ''],
      hengerurtartalom: [data.hengerurtartalom ?? '', Validators.required],
      teljesitmeny: [data.teljesitmeny ?? '', Validators.required],
    });


    if (data?.kepek && data?.kepek.length) {
      for (let i = 0; i < data?.kepek.length; i++) {
        const contentType = 'image/png';

        this.selectedFiles.push(new File([this.base64ToBlob(data.kepek[i], contentType)], 'img_' + i + '.png', {type: contentType}));
        this.previewImageUrls.push('data:image/jpeg;base64,' + data.kepek[i]);
      }
    }
  }

  onFileSelected(event: any): void {
    const files = event.target.files as FileList;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.selectedFiles.push(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.previewImageUrls.push(reader.result as string | ArrayBuffer);
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.previewImageUrls.splice(index, 1);
  }

  showOverlay(index: number): void {
    this.hoverIndex = index;
  }

  hideOverlay(): void {
    this.hoverIndex = null;
  }


  onSave() {
    const request = {
      id: this.data.id,
      cim: this.form.value.cim,
      rovidLeiras: this.form.value.rovidLeiras,
      hosszuLeiras: this.form.value.hosszuLeiras,
      marka: this.form.value.marka,
      modell: this.form.value.modell,
      ar: this.numberToString(this.form.value.ar),
      kedvezmenyesAr: this.numberToString(this.form.value.kedvezmenyesAr),
      motor: this.form.value.motor,
      kivitel: this.form.value.kivitel,
      uzemanyag: this.form.value.uzemanyag,
      sebessegvalto: this.form.value.sebessegvalto,
      allapot: this.form.value.allapot,
      hengerurtartalom: this.form.value.hengerurtartalom,
      teljesitmeny: this.form.value.teljesitmeny
    };

    const formData = new FormData();
    formData.append('request', new Blob([JSON.stringify(request)], {
      type: 'application/json'
    }));

    for (const image of this.selectedFiles) {
      formData.append('image', image);
    }

    this.apiService.createOrUpdateJarmu(formData).subscribe((resp) => {
      this.snackbar.openFromComponent(SuccessSnackbarComponent, {
        data: {message: 'Hirdetés sikeresen elmentve!'},
        duration: 5000
      });
      this.dialogRef.close();
    }, () => {
      this.snackbar.openFromComponent(ErrorSnackbarComponent, {
        data: {message: 'Hirdetés nem létrehozható!'},
        duration: 5000
      });
    });
  }


  createFileFromJson(obj: any, options?: { type?: string; fileName?: string }) {
    const type = options?.type ?? 'application/json';
    const requestBlob = new Blob([JSON.stringify(obj)], {
      type
    });

    return new File([requestBlob], options?.fileName ?? 'request.json', {
      type
    });
  }

  numberToString(stringNumber: number | string) {
    return stringNumber ? String(stringNumber).replace(/\s/g, '') : undefined;
  }

  base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);  // Decode base64 string
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}

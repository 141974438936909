import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  loginForm!: FormGroup;
  tokenFormVisible = false;
  tokenForm!: FormGroup;

  constructor(
      private dialogRef: MatDialogRef<LoginDialogComponent>,
      private fb: FormBuilder,
      private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.tokenForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      token: ['', Validators.required]
    });
  }

  onTokenSubmit(): void {
    // Handle token submission
    this.apiService.login(this.tokenForm.value).subscribe((resp) => {
      // token kezelése
      localStorage.setItem('access_token', resp.token);
      this.dialogRef.close();
    }, () => {
      // snackbar error
    });
  }

  onGetToken(): void {
    this.apiService.getToken(this.loginForm.value).subscribe(() => {
      this.tokenFormVisible = true;
      this.tokenForm.patchValue(this.loginForm.value);
    }, () => {
      // snackbar error
    });
  }
}

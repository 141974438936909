import {Component, Input, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {ApiService} from '../../../services/api/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorSnackbarComponent} from '../snackbar/error-snackbar/error-snackbar.component';
import {SuccessSnackbarComponent} from '../snackbar/success-snackbar/success-snackbar.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input()
  isJarmuHirdetes = false;

  form: FormGroup;

  constructor(
      private viewportScroller: ViewportScroller,
      private apiService: ApiService,
      private snackBar: MatSnackBar,
      private formBuilder: FormBuilder
  ) {
    this.initForm();
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      nev: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      targy: ['', Validators.required],
      telefon: ['', Validators.required],
      uzenet: ['', Validators.required]
    });
  }

  submit() {
    this.apiService.sendContactMail({
      url: this.isJarmuHirdetes ? window.location.toString() : undefined,
      ...this.form.value
    })
    .subscribe(() => {
      this.snackBar.openFromComponent(SuccessSnackbarComponent, {
        duration: 5000,
        data: {message: 'E-mail sikeres elküldve! Kollégáink hamarosan keresni fogják!'}
      });
      this.form.reset({onlySelf: true});
    }, () => {
      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        duration: 5000,
        data: {message: 'E-mail jelenleg nem kiküldhető, kérjük próbálja később újra!'}
      });
    });
  }
}

<div class="page-title-area responsive-about-header"
     style="background-image: url('../../../assets/img/admin_bg.jpg');">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h1></h1>
        </div>
      </div>
    </div>
  </div>
</div>

<section id="admin" class="how-work-area pt-100 pb-70">
  <div class="container">
    <div class="container">
      <div class="row" style="margin-bottom: 2rem">
        <div class="col-lg-12 col-md-12 centerAndFontSize">
          <button mat-raised-button color="primary" (click)="openCarDialog({})">Új jármű</button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="mat-elevation-z8 table-container">
        <table mat-table [dataSource]="dataSource" matSort matTableResponsive>
          <!-- ID Column -->
          <ng-container matColumnDef="marka">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Márka</th>
            <td mat-cell *matCellDef="let row"> {{row.marka}} </td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="modell">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Modell</th>
            <td mat-cell *matCellDef="let row"> {{row.modell}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="ar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ár</th>
            <td mat-cell *matCellDef="let row"> {{row.ar}} </td>
          </ng-container>

          <!-- Fruit Column -->
          <ng-container matColumnDef="kedvezmenyesAr" class="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Kedvezményes ár</th>
            <td mat-cell *matCellDef="let row"> {{row.kedvezmenyesAr}} </td>
          </ng-container>

          <!-- Fruit Column -->
          <ng-container matColumnDef="motor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Motor</th>
            <td mat-cell *matCellDef="let row"> {{row.motor}} </td>
          </ng-container>

          <!-- Fruit Column -->
          <ng-container matColumnDef="kivitel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Kivitel</th>
            <td mat-cell *matCellDef="let row"> {{row.kivitel}} </td>
          </ng-container>

          <!-- Fruit Column -->
          <ng-container matColumnDef="uzemanyag">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Üzemanyag</th>
            <td mat-cell *matCellDef="let row"> {{row.uzemanyag}} </td>
          </ng-container>

          <!-- Fruit Column -->
          <ng-container matColumnDef="sebessegvalto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Váltó</th>
            <td mat-cell *matCellDef="let row"> {{row.sebessegvalto}} </td>
          </ng-container>

          <!-- Fruit Column -->
          <ng-container matColumnDef="actions" sticky>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="openCarDialog(row)">
                <mat-icon style="color: #8f2608">edit</mat-icon>
              </button>
              <button mat-icon-button (click)="openConfirmDialog(row.id)">
                <mat-icon style="color: #8f2608">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nincs adat</td>
          </tr>
        </table>
      </div>

    </div>
  </div>
  <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>

<div id="faq" class="faq-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Gyakran ismételt <span>Kérdések</span></h2>
      <br>
    </div>
    <div class="row">
      <div class="col-lg-5 col-md-12">
        <div class="faq-image">
          <img src="assets/common/faq.jpg" alt="image">
        </div>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="faq-accordion">
          <accordion>
            <accordion-group heading="Hogyan működik egy lízing?">
              <p>A autólízing lényegében egy szerződés Ön és az autókereskedés / lízingbeadó között,
                amelytől lízingel. Amikor Ön aláírja a gépjárműlízinget, beleegyezik a lízingbeadó
                által meghatározott bizonyos feltételekbe.
              </p>
            </accordion-group>
            <accordion-group heading="Hogyan válasszam ki a számomra megfelelő járművet?">
              <p><strong>Igények és használat:</strong> Gondoljuk át, milyen céllal szeretnénk
                használni a járművet, és milyen funkciókra van szükségünk. Például városi
                közlekedésre, családi utazásokra vagy munkához.</p><br>
              <p><strong>Üzemanyag típusa és fogyasztás:</strong> Vegyük figyelembe az üzemanyag
                típusát (benzin, dízel, elektromos stb.) és a jármű fogyasztását annak érdekében,
                hogy hosszú távon fenntartható legyen számunkra.</p><br>
              <p><strong>Költségek:</strong> Tegyük fel a kérdést, hogy a jármű ára és
                fenntartása mennyire fér bele a költségvetésünkbe. Vegyük figyelembe az árát, a
                biztosítást, a fenntartási költségeket és az üzemanyagköltségeket.</p>
            </accordion-group>
            <accordion-group heading="Mire figyeljek oda egy lízing szerződés megkötésekor?">
              <p><strong>Kilométerkorlátok:</strong> Győződjünk meg arról, hogy a lízingdíj
                megfelelő kilométerkorlátokat tartalmaz-e az igényeinknek megfelelően. Túllépés
                esetén többletköltségek merülhetnek fel.</p><br>
              <p><strong>Karbiztosítás:</strong> Bizonyosodjunk meg róla, hogy a lízingdíj
                tartalmazza-e a szükséges karbiztosítást, és ha nem, akkor külön kell-e megkötni.
              </p><br>
              <p><strong>Visszaadási feltételek:</strong> Ismerjük meg a szerződés visszaadási
                feltételeit, ideértve a jármű állapotára vonatkozó követelményeket és esetleges
                büntetéseket.
              </p>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
  <div class="container">
    <div class="section-title">
      <h2 *ngIf="!isJarmuHirdetes">Lépjen <span>Kapcsolatba</span> Velünk</h2>
      <h2 *ngIf="isJarmuHirdetes">Érdekli Önt ez a jármű? Lépjen <span>Kapcsolatba</span> Velünk!
      </h2>
      <p>Megkereséseire kollégáink igyekeznek minél hamarabb válaszolni! </p>
    </div>
    <div class="row">
      <div class="col-lg-7 col-md-12">
        <div class="contact-form">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group mb-3">
                  <mat-form-field>
                    <mat-label>Név</mat-label>
                    <input matInput formControlName="nev">
                    <mat-error *ngIf="form.get('nev').invalid">Név kitöltése kötelező</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group mb-3">
                  <mat-form-field>
                    <mat-label>E-mail</mat-label>
                    <input matInput formControlName="email">
                    <mat-error *ngIf="form.get('email').invalid">E-mail kitöltése kötelező
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group mb-3">
                  <mat-form-field>
                    <mat-label>Tárgy</mat-label>
                    <input matInput formControlName="targy">
                    <mat-error *ngIf="form.get('targy').invalid">Tárgy kitöltése kötelező
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group mb-3">
                  <mat-form-field>
                    <mat-label>Telefon</mat-label>
                    <input matInput formControlName="telefon">
                    <mat-error *ngIf="form.get('telefon').invalid">Telefon kitöltése kötelező
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group mb-3">
                  <mat-form-field>
                    <mat-label>Üzenet</mat-label>
                    <textarea matInput formControlName="uzenet"></textarea>
                    <mat-error *ngIf="form.get('uzenet').invalid">Üzenet kitöltése kötelező
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-lg-12 col-md-12" style="text-align: right">
              <button mat-raised-button color="primary" (click)="submit()"
                      [disabled]="!this.form.valid">
                Küldés
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12">
        <div class="contact-info">
          <div class="d-table">
            <div>
              <ul>
                <li><i class="fas fa-map-marker-alt"></i> <span>Székhely:</span>6726 Szeged, Töltés
                  u. 11.
                </li>
                <li><i class="far fa-envelope"></i> <a href="mailto:pepcopremium@gmail.com"><span>E-mail:</span>pepcopremium@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content class="mat-typography" id="imageViewDialog" style="width: 80vw">
  <div class="row align-items-center">
    <owl-carousel-o [options]="feedbackSlides">
      <ng-template carouselSlide *ngFor="let kep of kepek">
        <div>
          <div class="image-container"
               [style.backgroundImage]="'url(' + getHirdetesImage(kep) + ')'"></div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</mat-dialog-content>

<div id="services" class="services-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Aktuális <span>Ajánlatunk</span></h2>
      <p>Az ajánlatunk folyamatosan frissül, a legújább járműveinkért keressen minket telefonon!</p>
    </div>

    <div class="row justify-content-center" *ngIf="hirdetesek.length">
      <div class="col-lg-4 col-md-6" *ngFor="let hirdetes of hirdetesek">
        <div class="single-services" [routerLink]="'/about/' + hirdetes.id">
          <div class="services-img">
            <img [src]="getHirdetesImage(hirdetes)" alt="services-img" height="250">
          </div>
          <div class="services-content">
            <h3>{{hirdetes.cim}}</h3>
            <p>{{hirdetes.rovidLeiras}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row empty-hirdetesek" style="text-align: center" *ngIf="!hirdetesek.length">
      <h4>Jelenleg <span>nincs</span> aktív hirdetésünk!</h4>
    </div>

  </div>
</div>

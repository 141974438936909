import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';

export interface HirdetesDTO {
  id: number;
  cim: string;
  rovidLeiras: string;
  images: string[];
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  hirdetesek: HirdetesDTO[] = [];

  constructor(private apiService: ApiService) {
  }

  ngOnInit() {
    this.apiService.getAllJarmuHirdetesek().subscribe((resp) => {
      this.hirdetesek = resp;
    });
  }

  getHirdetesImage(hirdetes: HirdetesDTO): string {
    if (hirdetes.images.length) {
      return 'data:image/jpeg;base64,' + hirdetes.images[0];
    }

    return 'assets/fallback.jpg';
  }


}

import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {LoginDialogComponent} from '../../login-dialog/login-dialog.component';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(
      private viewportScroller: ViewportScroller,
      public ngxSmartModalService: NgxSmartModalService,
      private router: Router,
      private dialog: MatDialog,
      public authService: AuthService
  ) {
  }

  classApplied = false;

  public onClick(elementId: string, shouldClose = false): void {
    const isHomePage = this.router.url === '/';

    if (!isHomePage) {
      this.router.navigate(['/']).then(() => {
        // Call setTimeout with a delay of 20ms
        setTimeout(() => {
          // Call your function after 20ms
          this.viewportScroller.scrollToAnchor(elementId);
        }, 200);
      });
    } else {
      this.viewportScroller.scrollToAnchor(elementId);
    }

    if (shouldClose) {
      this.toggleClass();
    }
  }

  public navigateToHasznaltauto() {
    window.open('https://www.hasznaltauto.hu/partner/pepco_premium_kft-16817', '_blank');
  }

  ngOnInit() {
  }

  openLoginDialog() {
    this.dialog.open(LoginDialogComponent);
  }

  logout(): void {
    this.authService.removeToken();
    const isHomePage = this.router.url === '/';
    if (isHomePage) {
      window.location.reload();
    }
    this.router.navigate(['/']);
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  toAdmin(): void {
    this.router.navigate(['/admin']);
    this.toggleClass();
  }
}

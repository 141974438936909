import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeOneComponent} from './components/home-one/home-one.component';
import {AboutComponent} from './components/common/about/about.component';
import {AdminComponent} from './components/admin/admin.component';
import {JwtTokenAuthGuard} from './services/jwt/jwt-token-auth.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeOneComponent,
    children: []
  },
  // {path: 'home-two', component: HomeTwoComponent},
  // {path: 'home-three', component: HomeThreeComponent},
  // {path: 'home-four', component: HomeFourComponent},
  // {path: 'home-five', component: HomeFiveComponent},
  // {path: 'home-six', component: HomeSixComponent},
  // {path: 'home-seven', component: HomeSevenComponent},
  // {path: 'home-eight', component: HomeEightComponent},
  // {path: 'home-nine', component: HomeNineComponent},
  // {path: 'home-ten', component: HomeTenComponent},
  // {path: 'home-eleven', component: HomeElevenComponent},
  // {path: 'home-twelve', component: HomeTwelveComponent},
  // {path: 'home-thirteen', component: HomeThirteenComponent},
  // {path: 'blog-details', component: BlogDetailsComponent},
  {path: 'about/:id', component: AboutComponent},
  {path: 'admin', component: AdminComponent, canActivate: [JwtTokenAuthGuard]},
  // Here add new pages component

  {path: '**', component: HomeOneComponent} // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

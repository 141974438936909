<section id="work" class="how-work-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Hogyan <span>Dolgozunk</span></h2>
      <p>Az ügyfelek elégedettsége számunkra elsődleges fontosságú. Célunk, hogy munkánk
        eredményeként ügyfeleink elégedetten és pozitív élménnyel vehessék át a kulcsokat.</p>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-how-work">
          <div class="icon">
            <i class="fas fa-info-circle"></i>
          </div>
          <h3>Tervezés</h3>
          <p>Felmérjük az ügyfél igényeit, kéréseit és megtaláljuk a tökéletes járművet.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-how-work">
          <div class="icon">
            <i class="fas fa-drafting-compass"></i>
          </div>
          <h3>Ügyintézés</h3>
          <p>Az ügyfél hátradőlhet, lazíthat amíg mi beszerezzük a kívánt járművet és elintézzük a
            papírmunkát.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-how-work">
          <div class="icon">
            <i class="far fa-paper-plane"></i>
          </div>
          <h3>Átadás</h3>
          <p>Miután a jármű beérkezik telephelyünkre, előkészítjük azt, és a papirok aláírását
            követően átadjuk az ügyfél számára.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>

import {Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {CarDialogComponent} from '../car-dialog/car-dialog.component';
import {ConfirmDialogComponent} from '../common/confirm-dialog/confirm-dialog.component';
import {ApiService} from '../../services/api/api.service';
import {ReszletesHirdetesData} from '../common/about/about.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  SuccessSnackbarComponent
} from '../common/snackbar/success-snackbar/success-snackbar.component';
import {ErrorSnackbarComponent} from '../common/snackbar/error-snackbar/error-snackbar.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  displayedColumns: string[] = ['actions', 'marka', 'modell', 'ar', 'kedvezmenyesAr', 'motor', 'kivitel', 'uzemanyag', 'sebessegvalto'];
  dataSource: MatTableDataSource<ReszletesHirdetesData>;
  selection = new SelectionModel<ReszletesHirdetesData>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  keresoForm = new FormControl('');
  kedvezmenyesAr = false;
  aktiv = true;
  inaktiv = false;

  constructor(public dialog: MatDialog, private apiService: ApiService, private snackbar: MatSnackBar) {
    this.fetchData();
  }

  openCarDialog(data: any) {
    const dialogRef = this.dialog.open(CarDialogComponent, {
      data
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchData();
    });
  }

  openConfirmDialog(id: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.apiService.deleteHirdetes(id).subscribe(() => {
          this.snackbar.openFromComponent(SuccessSnackbarComponent, {
            duration: 5000,
            data: {message: 'Hirdetés sikeresen törölve!'}
          });
          this.fetchData();
        }, () => {
          this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            duration: 5000,
            data: {message: 'Hirdetés pillanatnyilag nem törölhető!'}
          });
        });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  fetchData() {
    this.apiService.getAllAdminJarmuHirdetesek().subscribe((resp) => {
      this.dataSource = new MatTableDataSource<ReszletesHirdetesData>(resp);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}

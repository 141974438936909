<h2 mat-dialog-title style="text-align: center;">Bejelentkezés</h2>
<mat-divider></mat-divider>

<mat-dialog-content class="mat-typography" id="loginDialog">
  <div class="container">
    <div *ngIf="!tokenFormVisible">
      <form [formGroup]="loginForm" (ngSubmit)="onGetToken()">
        <div class="column">
          <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 1rem;">
            <mat-form-field appearance="fill">
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="email" placeholder="pelda@123.com">
              <mat-error *ngIf="loginForm.get('email')?.invalid">E-mail
                kitöltése kötelező
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <mat-form-field appearance="fill">
              <mat-label>Jelszó</mat-label>
              <input matInput type="password" formControlName="password" placeholder="*****">
              <mat-error *ngIf="loginForm.get('password')?.invalid">Jelszó kitöltése kötelező
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="tokenFormVisible">
      <form [formGroup]="tokenForm" (ngSubmit)="onGetToken()">
        <div class="column">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <mat-form-field appearance="fill">
              <mat-label>Token</mat-label>
              <input matInput formControlName="token" placeholder="123456">
              <mat-error *ngIf="tokenForm.get('token')?.invalid">Token kitöltése kötelező
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-divider style="margin-bottom: 1rem;"></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onGetToken()" *ngIf="!tokenFormVisible"
          [disabled]="!this.loginForm.valid">
    Küldés
  </button>
  <button mat-raised-button color="primary" (click)="onTokenSubmit()" *ngIf="tokenFormVisible"
          [disabled]="!this.tokenForm.valid">Token
    beküldése
  </button>
</mat-dialog-actions>


<section id="filter">
    <div class="container">
        <div class="row" style="margin-bottom: 2rem">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form">
                    <form #contactForm="ngForm">
                        <div class="row">
                            <div class="col-lg-3 col-md-4">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Márka</mat-label>
                                    <mat-select [formControl]="toppings" multiple>
                                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-md-4">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Modell</mat-label>
                                    <mat-select [formControl]="toppings" multiple>
                                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-md-4">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Üzemanyag</mat-label>
                                    <mat-select [formControl]="toppings" multiple>
                                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-md-4">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Kivitel</mat-label>
                                    <mat-select [formControl]="toppings" multiple>
                                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-4">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Vételár -tól</mat-label>
                                    <input matInput appNumberDelimiter class="amount-field" placeholder="0">
                                    <span matTextSuffix> Ft</span>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-md-4">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Vételár -ig</mat-label>
                                    <input matInput appNumberDelimiter class="amount-field">
                                    <span matTextSuffix> Ft</span>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-md-4"></div>
                            <div class="col-lg-3 col-md-4" style="text-align: right">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid"><i class="fa fa-magnifying-glass"></i>Keresés</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

import {Component, Input, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    @Input()
    filterOptions: any = {};

    toppings = new FormControl('');
    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    constructor(private viewportScroller: ViewportScroller) {}

    ngOnInit() {
    }

}

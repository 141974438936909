<div class="page-title-area responsive-about-header"
     style="background-image: url('../../../../assets/img/admin_bg.jpg');">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h1></h1>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="about" class="about-area ptb-100">
  <div class="container" *ngIf="hirdetes">
    <div class="section-title">
      <h2>{{ cim[0] }} <span>{{ cim[1] }} </span></h2>
      <p>{{ hirdetes.rovidLeiras }}</p>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12" style="margin-bottom: 2rem">
        <owl-carousel-o [options]="feedbackSlides">
          <ng-template carouselSlide *ngFor="let kep of hirdetes.kepek">
            <div class="image-container"
                 [style.backgroundImage]="'url(' + getHirdetesImage(kep) + ')'"
                 (click)="onImageClick()"
            ></div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <div class="section-title" *ngIf="hirdetes.kedvezmenyesAr">
            <h1>Kedvezményes ár: <span>{{ hirdetes.kedvezmenyesAr | thousandFormat }}
              Ft</span>
            </h1>
            <h2 style="text-decoration: line-through">Eredeti vételár:
              <span>{{ hirdetes.ar | thousandFormat }} Ft</span></h2>
          </div>
          <div class="section-title" *ngIf="!hirdetes.kedvezmenyesAr">
            <h2>Vételár: <span>{{ hirdetes.ar | thousandFormat }} Ft</span></h2>
          </div>
          <ul class="features-list">
            <li><span>Évjárat:</span> {{ hirdetes.evjarat }}</li>
            <li><span>Állapot:</span> {{ hirdetes.allapot }}</li>
            <li><span>Kivitel:</span> {{ hirdetes.kivitel }}</li>
            <li><span>Km. óra állás:</span> {{ hirdetes.kmAllas }} km</li>
            <li><span>Üzemanyag:</span> {{ hirdetes.uzemanyag }}</li>
            <li><span>Hengerűrtartalom:</span> {{ hirdetes.hengerurtartalom }} cm3</li>
            <li><span>Teljesítmény:</span> {{ hirdetes.teljesitmeny }}</li>
            <li><span>Sebességváltó:</span> {{ hirdetes.sebessegvalto }}</li>
          </ul>
          <p>{{ hirdetes.hosszuLeiras }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-contact [isJarmuHirdetes]="true"></app-contact>

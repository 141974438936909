import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../../services/api/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorSnackbarComponent} from '../snackbar/error-snackbar/error-snackbar.component';
import {MatDialog} from "@angular/material/dialog";
import {ImageViewDialogComponent} from "../../image-view-dialog/image-view-dialog.component";

export interface ReszletesHirdetesData {
  ar: number;
  cim: string;
  hengerurtartalom: string;
  hosszuLeiras: string;
  id: number;
  kedvezmenyesAr: number;
  kepek: string[];
  kivitel: string;
  marka: string | null;
  modell: string | null;
  motor: string;
  rovidLeiras: string | null;
  sebessegvalto: string;
  teljesitmeny: string;
  uzemanyag: string;
  evjarat: string;
  allapot: string;
  kmAllas: number;
}


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
      private activatedRoute: ActivatedRoute,
      private apiService: ApiService,
      private snackBar: MatSnackBar,
      private dialog: MatDialog
  ) {
    this.apiService.getJarmuHirdetesById(activatedRoute.snapshot.paramMap.get('id')).subscribe((resp) => {
      this.hirdetes = resp;
      this.cim = this.getCim(resp.cim);
    }, () => {
      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        duration: 5000,
        data: {message: 'A hirdetés nem található, kérjük navigáljon vissza a főoldalra!'}
      });
    });
  }

  hirdetes: ReszletesHirdetesData;
  cim: [string, string];

  feedbackSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: 'fadeOut',
    autoplayHoverPause: true,
    autoplay: false,
    mouseDrag: false,
    items: 1,
    navText: [
      '<i class=\'fa fa-angle-left\'></i>',
      '<i class=\'fa fa-angle-right\'></i>'
    ]
  };

  ngOnInit() {
  }

  getCim(str: string): [string, string] {
    // Find the index of the first space
    const firstSpaceIndex = str.indexOf(' ');

    // If no space is found, return the entire string as the first word and an empty string as the rest
    if (firstSpaceIndex === -1) {
      return [str, ''];
    }

    // Split the string at the first space
    const firstWord = str.slice(0, firstSpaceIndex);
    const restOfString = str.slice(firstSpaceIndex + 1);

    return [firstWord, restOfString];
  }

  getHirdetesImage(kepString: string): string {
    return kepString.length ? 'data:image/jpeg;base64,' + kepString : 'feltoltesAlatt';
  }

  onImageClick() {
    if (this.hirdetes.kepek.length) {
      this.dialog.open(ImageViewDialogComponent, {
        panelClass: ['md:w-3/5', 'w-full'],
        maxHeight: '85vh',
        data: {
          kepek: this.hirdetes.kepek,
          index: 1
        }
      });
    }
  }
}

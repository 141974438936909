import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {StickyNavModule} from 'ng2-sticky-nav';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CountUpModule} from 'ngx-countup';
import {NgxTypedJsModule} from 'ngx-typed-js';
import {AccordionModule} from 'ngx-accordion';
import {TabsModule} from 'ngx-tabset';
import {TooltipModule} from 'ng2-tooltip-directive';
import {ParticlesModule} from 'ngx-particle';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/common/preloader/preloader.component';
import {HomeOneComponent} from './components/home-one/home-one.component';
import {NavbarComponent} from './components/common/navbar/navbar.component';
import {WelcomeComponent} from './components/common/welcome/welcome.component';
import {AboutComponent} from './components/common/about/about.component';
import {StrategyComponent} from './components/common/strategy/strategy.component';
import {WhoWeAreComponent} from './components/common/who-we-are/who-we-are.component';
import {CtaComponent} from './components/common/cta/cta.component';
import {SkillComponent} from './components/common/skill/skill.component';
import {FunfactsComponent} from './components/common/funfacts/funfacts.component';
import {TeamComponent} from './components/common/team/team.component';
import {HowWeWorkComponent} from './components/common/how-we-work/how-we-work.component';
import {ServicesComponent} from './components/common/services/services.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {SubscribeComponent} from './components/common/subscribe/subscribe.component';
import {PartnerComponent} from './components/common/partner/partner.component';
import {BlogComponent} from './components/common/blog/blog.component';
import {FeedbackComponent} from './components/common/feedback/feedback.component';
import {FaqComponent} from './components/common/faq/faq.component';
import {PricingComponent} from './components/common/pricing/pricing.component';
import {WorkComponent} from './components/common/work/work.component';
import {
  WhyWeDifferentComponent
} from './components/common/why-we-different/why-we-different.component';
import {ContactComponent} from './components/common/contact/contact.component';
import {HomeTwoComponent} from './components/home-two/home-two.component';
import {HomeThreeComponent} from './components/home-three/home-three.component';
import {HomeFourComponent} from './components/home-four/home-four.component';
import {HomeFiveComponent} from './components/home-five/home-five.component';
import {ServicesTwoComponent} from './components/common/services-two/services-two.component';
import {HomeSixComponent} from './components/home-six/home-six.component';
import {HomeSevenComponent} from './components/home-seven/home-seven.component';
import {HomeEightComponent} from './components/home-eight/home-eight.component';
import {HomeNineComponent} from './components/home-nine/home-nine.component';
import {HomeTenComponent} from './components/home-ten/home-ten.component';
import {HomeElevenComponent} from './components/home-eleven/home-eleven.component';
import {HomeTwelveComponent} from './components/home-twelve/home-twelve.component';
import {HomeThirteenComponent} from './components/home-thirteen/home-thirteen.component';
import {BlogDetailsComponent} from './components/blog-details/blog-details.component';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NumberDelimiterDirective} from './utils/thousand-delimiter.directive';
import {AdminComponent} from './components/admin/admin.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FilterComponent} from './components/common/filter/filter.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSortModule} from '@angular/material/sort';
import {CarDialogComponent} from './components/car-dialog/car-dialog.component';
import {
  MatTableResponsiveDirective
} from './utils/mat-table-directive/mat-table-responsive.directive';
import {MatListModule} from '@angular/material/list';
import {ConfirmDialogComponent} from './components/common/confirm-dialog/confirm-dialog.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './services/jwt/jwt-interceptor';
import {LoginDialogComponent} from './components/login-dialog/login-dialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {
  SuccessSnackbarComponent
} from './components/common/snackbar/success-snackbar/success-snackbar.component';
import {
  ErrorSnackbarComponent
} from './components/common/snackbar/error-snackbar/error-snackbar.component';
import {ThousandFormatPipe} from './utils/thousand-format.pipe';
import {ImageViewDialogComponent} from './components/image-view-dialog/image-view-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HomeOneComponent,
    NavbarComponent,
    WelcomeComponent,
    AboutComponent,
    StrategyComponent,
    WhoWeAreComponent,
    CtaComponent,
    SkillComponent,
    FunfactsComponent,
    TeamComponent,
    HowWeWorkComponent,
    ServicesComponent,
    FooterComponent,
    SubscribeComponent,
    PartnerComponent,
    BlogComponent,
    FeedbackComponent,
    FaqComponent,
    PricingComponent,
    WorkComponent,
    WhyWeDifferentComponent,
    ContactComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    HomeFourComponent,
    HomeFiveComponent,
    ServicesTwoComponent,
    HomeSixComponent,
    HomeSevenComponent,
    HomeEightComponent,
    HomeNineComponent,
    HomeTenComponent,
    HomeElevenComponent,
    HomeTwelveComponent,
    HomeThirteenComponent,
    BlogDetailsComponent,
    NumberDelimiterDirective,
    AdminComponent,
    FilterComponent,
    CarDialogComponent,
    MatTableResponsiveDirective,
    ConfirmDialogComponent,
    LoginDialogComponent,
    SuccessSnackbarComponent,
    ErrorSnackbarComponent,
    ThousandFormatPipe,
    ImageViewDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StickyNavModule,
    NgxSmartModalModule.forRoot(),
    NgxScrollTopModule,
    CarouselModule,
    FormsModule,
    AccordionModule,
    CountUpModule,
    TabsModule,
    TooltipModule,
    NgxTypedJsModule,
    ParticlesModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    MatSortModule,
    MatButtonModule,
    FormsModule,
    MatListModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHandler} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = environment.api_url;

  constructor(private http: HttpClient, private httpHandler: HttpHandler) {
  }

  // Example of sending a GET request
  getToken(data: { email: string, password: string }): Observable<any> {
    return this.http.post<string>(`${this.baseUrl}/token`, data);
  }

  // Example of sending a POST request
  login(data: { email: string, password: string, token: string }): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/login`, data);
  }

  // Add other methods for different HTTP request types as needed
  sendContactMail(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/contact`, data);
  }

  createOrUpdateJarmu(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/jarmu/save`, data);
  }

  getAllJarmuHirdetesek(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/hirdetesek`);
  }

  getAllAdminJarmuHirdetesek(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/hirdetesek`);
  }

  getJarmuHirdetesById(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/hirdetes/`
        + id);
  }

  deleteHirdetes(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/admin/hirdetes/`
        + id);
  }
}

import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class JwtTokenAuthGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(): boolean {
    const token = localStorage.getItem('access_token');

    if (token) {
      // Token is set, check validity
      const decodedToken = this.decodeToken(token);

      if (decodedToken && this.isTokenValid(decodedToken)) {
        return true; // Allow access to route
      }
    }

    // Token is not set or invalid, redirect to login page
    this.router.navigate(['/']);
    localStorage.removeItem('access_token');
    return false;
  }

  private decodeToken(token: string): any {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      return null;
    }
  }

  private isTokenValid(decodedToken: any): boolean {
    const now = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp && now < decodedToken.exp;
  }
}

<div class="main-banner item-bg-one">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="main-banner-text">
          <h1>Pepco <span>Premium</span> Kft.</h1>
        </div>
      </div>
    </div>
  </div>
</div>

<app-welcome></app-welcome>

<!--<app-about></app-about>-->

<!--<app-blog></app-blog>-->

<!--<app-strategy></app-strategy>-->

<!--<app-who-we-are></app-who-we-are>-->

<!--<app-cta></app-cta>-->

<!--<app-skill></app-skill>-->

<!--<app-funfacts></app-funfacts>-->

<!--<app-team></app-team>-->

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<!--<app-why-we-different></app-why-we-different>-->

<!--<app-work></app-work>-->

<!--<app-pricing></app-pricing>-->

<app-feedback></app-feedback>

<app-faq></app-faq>

<!--<app-blog></app-blog>-->

<!--<app-partner></app-partner>-->

<!--<app-subscribe></app-subscribe>-->

<app-contact></app-contact>

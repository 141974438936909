<h2 mat-dialog-title style="text-align: center;">{{data?.title ?? 'Új jármű rögzítése'}}</h2>
<mat-divider></mat-divider>

<mat-dialog-content class="mat-typography" id="carDialog">
  <div class="container">
    <form [formGroup]="form">
      <h4>Hirdetés címe</h4>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <mat-form-field>
            <mat-label>Hirdetés címe</mat-label>
            <input matInput formControlName="cim">
            <mat-error *ngIf="form.get('cim').invalid">Cím kitöltése kötelező</mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-divider style="margin-bottom: 1rem;"></mat-divider>

      <div class="row">
        <h4>Képek</h4>

        <input type="file" (change)="onFileSelected($event)" accept="image/*" multiple>
        <div class="row">
          <div *ngFor="let imageUrl of previewImageUrls; let i = index"
               class="col-lg-4 col-md-4 col-sm-12 image-container"
               (mouseover)="showOverlay(i)" (mouseleave)="hideOverlay()">
            <img [src]="imageUrl" class="preview-image">
            <div class="overlay" *ngIf="hoverIndex === i">
              <button mat-icon-button color="primary" (click)="removeImage(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <mat-divider style="margin-bottom: 1rem;"></mat-divider>

      <div class="row">
        <h4>Jármű alapdatok</h4>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Márka</mat-label>
            <input matInput formControlName="marka">
            <mat-error *ngIf="form.get('marka').invalid">Márka kitöltése kötelező</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Modell</mat-label>
            <input matInput formControlName="modell">
            <mat-error *ngIf="form.get('modell').invalid">Modell kitöltése kötelező</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Üzemanyag típusa</mat-label>
            <input matInput formControlName="uzemanyag">
            <mat-error *ngIf="form.get('uzemanyag').invalid">Üzemanyag típusának kitöltése
              kötelező
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Kivitel</mat-label>
            <input matInput formControlName="kivitel">
            <mat-error *ngIf="form.get('kivitel').invalid">Kivitel kitöltése kötelező</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Motor típusa</mat-label>
            <input matInput formControlName="motor">
            <mat-error *ngIf="form.get('motor').invalid">Motor típusának kitöltése kötelező
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Váltó</mat-label>
            <input matInput formControlName="sebessegvalto">
            <mat-error *ngIf="form.get('sebessegvalto').invalid">Váltó kitöltése kötelező
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Állapot</mat-label>
            <input matInput formControlName="allapot">
            <mat-error *ngIf="form.get('allapot').invalid">Állapot kitöltése kötelező</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Hengerűrtartalom</mat-label>
            <input matInput formControlName="hengerurtartalom">
            <mat-error *ngIf="form.get('hengerurtartalom').invalid">Hengerűrtartalom kitöltése
              kötelező
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-4">
          <mat-form-field style="width: 100%;">
            <mat-label>Teljesítmény</mat-label>
            <input matInput formControlName="teljesitmeny">
            <mat-error *ngIf="form.get('teljesitmeny').invalid">Teljesítmény kitöltése kötelező
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <mat-form-field style="width: 100%;">
            <mat-label>Vételár</mat-label>
            <input matInput appNumberDelimiter class="amount-field" placeholder="0"
                   formControlName="ar">
            <span matTextSuffix> Ft</span>
            <mat-error *ngIf="form.get('ar').invalid">Ár kitöltése kötelező</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
          <mat-form-field style="width: 100%;">
            <mat-label>Kedvezményes ár</mat-label>
            <input matInput appNumberDelimiter class="amount-field"
                   formControlName="kedvezmenyesAr">
            <span matTextSuffix> Ft</span>
            <mat-error *ngIf="form.get('kedvezmenyesAr').invalid">Kivitel kitöltése kötelező
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-divider style="margin-bottom: 1rem;"></mat-divider>
      <h4>Hirdetés leírása</h4>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <mat-form-field>
            <mat-label>Hirdetés rövid leírása</mat-label>
            <textarea matInput formControlName="rovidLeiras" maxlength="250"></textarea>
            <mat-hint align="end">{{form.get('rovidLeiras')?.value?.length ?? 0}}/{{250}}</mat-hint>
            <mat-error *ngIf="form.get('rovidLeiras').invalid">Rövid leírás kitöltése kötelező
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <mat-form-field>
            <mat-label>Hirdetés hosszú leírása</mat-label>
            <textarea matInput formControlName="hosszuLeiras"></textarea>
            <mat-error *ngIf="form.get('hosszuLeiras').invalid">Hosszú leírás kitöltése kötelező
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="true">Mégse</button>
  <button mat-raised-button color="primary" cdkFocusInitial
          (click)="onSave()">Mentés
  </button>
</mat-dialog-actions>

<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied"
     stickyClass="sticky-box-shadow" ngStickyNav>
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/pepco_header_logo.png" width="150">
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto" style="height: 20px;">
        <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Főoldal</span></li>
        <li class="nav-item"><span class="nav-link" (click)="onClick('welcome')">Rólunk</span></li>
        <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Flottakezelés</span>
        </li>
        <li class="nav-item"><span class="nav-link" (click)="navigateToHasznaltauto()">Eladó</span>
        </li>
        <li class="nav-item"><span class="nav-link"
                                   (click)="onClick('feedback')">Rólunk</span></li>
        <li class="nav-item"><span class="nav-link" (click)="onClick('faq')">GYIK</span></li>
        <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Kapcsolat</span>
        </li>
        <li class="nav-item"><span class="nav-link" (click)="openLoginDialog()"
                                   *ngIf="!authService.isAuthenticated()"><i
            class="fa fa-right-to-bracket"></i></span></li>
        <li class="nav-item"><span class="nav-link" *ngIf="authService.isAuthenticated()"
                                   (click)="toAdmin()">Admin</span>
        </li>
        <li class="nav-item"><span class="nav-link" (click)="logout()"
                                   *ngIf="authService.isAuthenticated()"><i
            class="fa fa-right-to-bracket"></i></span></li>
      </ul>
      <ul class="navbar-nav ml-auto for-responsive">
        <li class="nav-item"><span class="nav-link" (click)="onClick('home', true)">Főoldal</span>
        </li>
        <li class="nav-item"><span class="nav-link" (click)="onClick('welcome', true)">Rólunk</span>
        </li>
        <li class="nav-item"><span class="nav-link"
                                   (click)="onClick('work', true)">Flottakezelés</span>
        </li>
        <li class="nav-item"><span class="nav-link" (click)="navigateToHasznaltauto()">Eladó járműveink</span>
        </li>
        <li class="nav-item"><span class="nav-link"
                                   (click)="onClick('feedback', true)">Rólunk mondták</span></li>
        <li class="nav-item"><span class="nav-link" (click)="onClick('faq', true)">GYIK</span></li>
        <li class="nav-item"><span class="nav-link"
                                   (click)="onClick('contact', true)">Kapcsolat</span>
        </li>
        <hr>
        <li class="nav-item"><span class="nav-link" (click)="openLoginDialog()"
                                   *ngIf="!authService.isAuthenticated()">Bejelentkezés</span>
        <li class="nav-item"><span class="nav-link" (click)="toAdmin()"
                                   *ngIf="authService.isAuthenticated()">Admin felület</span>
        <li class="nav-item"><span class="nav-link" (click)="logout()"
                                   *ngIf="authService.isAuthenticated()">Kijelentkezés</span>
        </li>
      </ul>
    </div>
  </div>
</nav>

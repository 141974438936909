import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appNumberDelimiter]'
})
export class NumberDelimiterDirective {

    constructor(private el: ElementRef<HTMLInputElement>) { }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string): void {
        this.el.nativeElement.value = this.transformValue(value);
    }

    private transformValue(value: string): string {
        // Remove non-numeric characters
        const numericValue = value.replace(/\D/g, '');

        // Add a whitespace after every third character
        const pattern = /(\d{1,3})(?=(\d{3})+(?!\d))/g;
        return numericValue.replace(pattern, '$1 ');
    }
}

import {Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'thousandFormat'
})
export class ThousandFormatPipe implements PipeTransform {

  transform(value: number | null | undefined, decimals: number = 0, locale: string = 'en-US'): string {
    if (isNaN(value) || value === null || value === undefined) return '';

    const formattedNumber = formatNumber(value, locale, `1.${decimals}-${decimals}`);
    return formattedNumber.replace(/,/g, ' ');
  }
}

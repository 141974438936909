import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {
  }

  isAuthenticated(): boolean {
    // Check if the access token exists in local storage
    const token = localStorage.getItem('access_token');
    return !!token; // Convert token to boolean (true if token exists, false if null or undefined)
  }

  getToken(): string | null {
    // Get the access token from local storage
    return localStorage.getItem('access_token');
  }

  removeToken(): void {
    // Get the access token from local storage
    return localStorage.removeItem('access_token');
  }
}

<div id="welcome" class="welcome-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Üdvözöljük <span>Önöket</span></h2>
      <p>Vállalkozásunk sokéves tapasztalattal rendelkezik lízingügyletek lebonyolításában és
        használt járművek kereskedelmében.</p>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="far fa-comments"></i>
          </div>
          <h3>Ügyfélközpontúság</h3>
          <p>Ügyintézésünk során mindig az Ön igényei állnak elsö helyen. az Ön maximális
            elégedettsége! </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="far fa-edit"></i>
          </div>
          <h3>Egyedi ajánlatok</h3>
          <p>Ne elégedjen meg az átlagossal. Fedezze fel egyedi ajánlatainkat, melyek pontosan az Ön
            igényeire szabottak!</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
          <div class="icon">
            <i class="fas fa-car-alt"></i>
          </div>
          <h3>Változatos kínálat</h3>
          <p>Böngéssze változatos kínálatunkat, hogy megtalálja az Ön számára tökéletes
            megoldást!</p>
        </div>
      </div>
    </div>
  </div>
  <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
